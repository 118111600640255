.sysFloatBtn {
  .ant-float-btn-body {
    .ant-float-btn-content {
      width: 100%;
      height: 100%;
      padding: 0;

      .ant-float-btn-icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}