#root,
body,
html {
  height: 100%;
}

@font-face{
  font-family: AlibabaPuHuiTi;
  src: url("../../assets/font/AlibabaPuHuiTi-3-55-Regular.ttf");
}

*{
  font-family: 'AlibabaPuHuiTi';
}

.ant-layout {
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: #f3f5f6;
}