.sysExhibit {
  .active_laboratory {
    .tab_bg {
      background-color: #F6C528;
    }

    .tab_text {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .active_authentication {
    .tab_bg {
      background-color: #3BBADA;
    }

    .tab_text {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .active_patent {
    .tab_bg {
      background-color: #FF995E;
    }

    .tab_text {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .active_copyright {
    .tab_bg {
      background-color: #3BBADA;
    }

    .tab_text {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .active_honor {
    .tab_bg {
      background-color: #F7726F;
    }

    .tab_text {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  :where(.css-dev-only-do-not-override-1gelsm8).ant-carousel .slick-dots li button{
    height: 10px;
    border-radius: 10px;
  }

  :where(.css-dev-only-do-not-override-1gelsm8).ant-carousel .slick-dots li{
    width: 30px;
  }

  :where(.css-dev-only-do-not-override-1gelsm8).ant-carousel .slick-dots li.slick-active{
    width: 42px;
  }
}